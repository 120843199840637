import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import strings from "../../../../i18n/strings";
import DonationsStore from "../../../store/DonationsStore";
import { ReactComponent as MoreIcon } from "../../../../images/icons/more.svg";

import styles from "./DonationsTable.module.scss";
import { formatCurrency } from "../../../utils/common";
import EventStore from "../../../store/EventStore";
import { Button, MenuItem } from "../../../components/common";
import { IconButton, Menu } from "@material-ui/core";
import { IDonationsResponse } from "../../../api/apiResponseTypes";
import { Link } from "react-router-dom";
import DonationConfirmDeleteCommentDialog from "../DonationsDialogs/DonationConfirmDeleteDialog";
import DialogsStore, { Dialogs } from "../../../store/DialogsStore";
import UserStore from "../../../store/UserStore";

interface IProps {
  data: IDonationsResponse[];
}

const DonationsTable = observer(({data}: IProps) => {
  const currency = formatCurrency(EventStore.event?.currency);
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const country = EventStore.event?.location.country;
  const [tableData, setTableData] = useState<IDonationsResponse[]>(data);

  useEffect(() => {
    setTableData(data?.slice(0, 20));
  }, [data]);

  const toggleShowMore = () => {
    setTableData(data);
  };

  const handleClick = (event: any, item: IDonationsResponse) => {
    setAnchorEl(event.currentTarget);
    DonationsStore.selectItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chargeId = DonationsStore.selectedItem?.chargeId;

  let bookBoxTotal = DonationsStore.getBookBoxTotal();

  return (
    <>
      <table
        className={
          data?.length === tableData?.length ? "table" : "tableWithBottomBorder"
        }
      >
        <thead>
        <tr>
          <th className={styles.dateColumn}>
            {strings.donations.table_columns.date}
          </th>
          <th className={styles.donationsColumn}>
            {strings.donations.table_columns.donation}
          </th>
          {bookBoxTotal > 0 &&
              <th className={styles.donationsColumn}>
                {strings.donations.table_columns.book_gift}
              </th>}
          <th className={styles.beneficiaryColumn}>
            {strings.donations.table_columns.beneficiary}
          </th>

          <th>{strings.donations.table_columns.donor}</th>
          <th>{strings.donations.table_columns.corp_matching}</th>

          {country === "UK" && (
            <th>{strings.donations.table_columns.gift_aid}</th>
          )}

          <th></th>
          {/* <th>{strings.donations.table_columns.stripe}</th> */}
        </tr>
        </thead>

        <tbody>
        <>
          {tableData?.length ? (
            <>
              {tableData.map((item) => (
                <tr key={item.id} className={styles.tableRow}>
                  <td className={styles.dateCell}>
                    {moment(item.created).format("MM/DD/YYYY")}
                  </td>

                  <td className={styles.amountCell}>
                    {currency + item.amount}
                  </td>

                  {bookBoxTotal > 0 &&
                      <td className={styles.bookGiftCell}>
                        {item.bookBoxAmount ? currency + item.bookBoxAmount : '-'}
                      </td>}

                  <td>
                    <div className={styles.beneficiaryWrapper}>
                      <div className={styles.avatarWrapper}>
                        {item.beneficiary?.avatar && (
                          <img src={item.beneficiary?.avatar} alt=""/>
                        )}
                      </div>

                      <div>
                        {item.team ? (
                          <Link
                            to={`/students/${item.beneficiary?.memberId}`}
                            className={styles.name}
                          >
                            {item.beneficiary?.displayName || ""}
                          </Link>
                        ) : (
                          <span className={styles.name}>
                              {item.beneficiary?.displayName || ""}
                            </span>
                        )}

                        <span className={styles.description}>
                            {item.team?.name || ""}
                          </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className={styles.nameWrapper}>
                      <span className={styles.name}>{item.donorName}</span>

                      <a
                        href={`mailto:${item.donorEmail}`}
                        target="_blank"
                        rel="noreferrer"
                        className={styles.email}
                      >
                        {item.donorEmail}
                      </a>

                      <span className={styles.comment}>
                          {item.donorMessage}
                        </span>
                    </div>
                  </td>

                  <td>{item.matchCorp ? strings.donations.yes : "-"}</td>

                  {country === "UK" && (
                    <td>{item.giftAid ? strings.donations.yes : "-"}</td>
                  )}

                  <td className={styles.actionCell}>
                    <IconButton onClick={(e) => handleClick(e, item)}>
                      <MoreIcon/>
                    </IconButton>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <div className="noDataTable">
              <div>{strings.donations.no_data}</div>
            </div>
          )}
        </>
        </tbody>
      </table>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div onClick={handleClose}>

          {UserStore.user?.role === 'EA' &&
              <MenuItem onClick={() => 123}>
                  <a
                      href={`https://dashboard.stripe.com/payments/${chargeId}`}
                      target="_blank"
                      rel="noreferrer"
                  >
                    {strings.donations.menu.view_stripe_transaction}
                  </a>
              </MenuItem>}

          <MenuItem
            onClick={() => DialogsStore.setActiveDialog(
              Dialogs.DONATION_DELETE_COMMENT_CONFIRM
            )}
          >
            {strings.donations.menu.clear_donor_comment}
          </MenuItem>
        </div>
      </Menu>

      {!DonationsStore.loading &&
      tableData?.length &&
      tableData?.length !== data?.length ? (
        <Button className={styles.showMoreBtn} onClick={toggleShowMore}>
          {strings.buttons.show_more}
        </Button>
      ) : null}

      <DonationConfirmDeleteCommentDialog/>
    </>
  );
});

export default DonationsTable;
